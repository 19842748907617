<template>
  <input
    :id="id"
    :value="value"
    :checked="isChecked"
    :class="[
      { 'is-dark': dark },
      { 'has-error': hasError }
    ]"
    v-bind="$attrs"
    type="checkbox"
    class="w-5 h-5 base-checkbox rounded focus:ring-2"
    @change="onChange"
  >
  <label
    v-if="label"
    :for="id"
    class="ms-3 text-sm font-medium"
    :class="[
      dark ? 'text-gray-300' : 'text-brand-black',
      { 'text-brand-error': hasError },
    ]"
  >{{ label }}</label>
</template>

<script setup lang="ts">
import { computed, type InputHTMLAttributes } from 'vue';

interface Props extends /* @vue-ignore */ Partial<InputHTMLAttributes> {
  id: string
  label?: string
  modelValue?: string | number | string[] | number[] | boolean | null
  value?: string
  trueValue?: boolean | string | number
  falseValue?: boolean | string | number
  dark?: boolean
  hasError?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  trueValue: true,
  falseValue: false,
  dark: false,
  modelValue: undefined,
  value: undefined,
  hasError: false,
});

const emit = defineEmits(['update:modelValue']);

const isChecked = computed(() => {
  if (props.modelValue instanceof Array) {
    const value = props.value as never;
    return props.modelValue.includes(value);
  }
  return props.modelValue === props.trueValue;
});

function onChange (event: any) {
  const isChecked = event.target.checked as HTMLInputElement;
  if (props.modelValue instanceof Array) {
    const newValue = [...props.modelValue];
    const checkboxValue = props.value as never;
    if (isChecked) {
      newValue.push(checkboxValue);
    } else {
      newValue.splice(newValue.indexOf(checkboxValue), 1);
    }
    emit('update:modelValue', newValue);
  } else {
    emit('update:modelValue', isChecked ? props.trueValue : props.falseValue);
  }
}
</script>

<style scoped>
.base-checkbox {
  @apply focus:ring-blue-500 shadow-md;
  &:not(.has-error) {
    @apply border-brand-black/20;
  }
  &:checked {
    @apply bg-brand-white border-brand-black/20;
    background-image: url('data:image/svg+xml,<svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/></svg>');
  }
  &.is-dark {
    @apply text-brand-black bg-brand-white border-none;
    &.has-error {
      @apply ring-brand-warning ring-2;
    }
  }
  &:not(.is-dark) {
    .has-error {
      @apply ring-brand-error ring-2;
    }
  }
}

</style>
